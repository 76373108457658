import React from 'react';

// styling imports
import './About.scss';

import ProfilePic from '../../assets/pfp.png'

const About = () => {
    return ( 
        <div className="about-wrapper">
            <h2 className="title">About</h2>
            <div className="content-container">
                <p>
                    My name is Elijah McKay.  I am a Full-stack Software Engineer at G2 Supply with a background in packaging design, a love for learning skills, and a fascination with technology. 
                    <br />
                    <br />
                    I am also finishing my education at Lambda School, where I have studied technologies such as Javascript, React.js, Node.js, Python, PostgreSQL, and more.
                </p>
                <img src={ProfilePic} alt="headshot"/>
            </div>
            <h3>Check out my <a href="https://linkedin.com/in/elijahmckay" target="_blank" rel="noopener noreferrer"><u>LinkedIn</u></a></h3>
        </div>
     );
}
 
export default About;