import React from 'react';

// component imports
import NavBar from './components/NavBar/NavBar';
import Home from './components/Home/Home';
import Skills from './components/Skills/Skills';
import Projects from './components/Projects/Projects';
import About from './components/About/About';
import Contact from './components/Contact/Contact';

// library imports
import { Route } from 'react-router-dom';

// styling & image imports
import './App.scss';

function App() {

  return (
    <div className="App">
      <NavBar />
      <Route exact path="/" component={Home} />
      <Route path="/skills" component={Skills} />
      <Route path="/projects" component={Projects} />
      <Route path="/about" component={About} />
      <Route path="/contact" component={Contact} />
    </div>
  );
}

export default App;
