import React from 'react';

// styling imports 
import './Skills.scss';

// icon imports 

import ReactIcon from '../../assets/devicon-master/icons/react/react-original-wordmark.svg'
import NodeJSIcon from '../../assets/devicon-master/icons/nodejs/nodejs-original-wordmark.svg'
import PythonIcon from '../../assets/devicon-master/icons/python/python-original-wordmark.svg'
import PostgreSQLIcon from '../../assets/devicon-master/icons/postgresql/postgresql-plain-wordmark.svg'
import JSIcon from '../../assets/devicon-master/icons/javascript/javascript-original.svg'

import MongoDBIcon from '../../assets/devicon-master/icons/mongodb/mongodb-original-wordmark.svg'
import HTMLIcon from '../../assets/devicon-master/icons/html5/html5-original-wordmark.svg'
import CSSIcon from '../../assets/devicon-master/icons/css3/css3-original-wordmark.svg'
import GitIcon from '../../assets/devicon-master/icons/git/git-plain-wordmark.svg'
import ExpressIcon from '../../assets/devicon-master/icons/express/express-original-wordmark.svg'

const Skills = () => {
    return ( 
        <div className="skills-wrapper">
            <h2 className="title">Skills</h2>
            <div className="skills-container">
                <div>
                    <img src={JSIcon} class="icon" alt="programming icon" />
                    <img src={ReactIcon} class="icon" alt="programming icon" />
                    <img src={NodeJSIcon} class="icon" alt="programming icon" />
                    <img src={PythonIcon} class="icon" style={{marginTop: "3rem"}} alt="programming icon" />
                    <img src={PostgreSQLIcon} class="icon" style={{marginTop: "3rem"}} alt="programming icon" />
                </div>
                <div>
                    <img src={HTMLIcon} class="icon" alt="programming icon" />
                    <img src={CSSIcon} class="icon" alt="programming icon"  />
                    <img src={ExpressIcon} class="icon" alt="programming icon" />
                    <img src={GitIcon} class="icon" style={{marginTop: "3rem"}} alt="programming icon" />
                    <img src={MongoDBIcon} class="icon" style={{marginTop: "3rem"}} alt="programming icon" />
                </div>
            </div>
            <h3>See more on <a href="https://github.com/ElijahMcKay" target="_blank" rel="noopener noreferrer"><u>GitHub</u></a></h3>
        </div>

     );
}
 
export default Skills;