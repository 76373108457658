import React from 'react';
import ReactDOM from 'react-dom';

// component imports
import App from './App';

// library imports
import { BrowserRouter } from 'react-router-dom'

//styling imports
import './index.css';


ReactDOM.render(
    <BrowserRouter>
       <App />
    </BrowserRouter>,
    document.getElementById('root')
 );