import React from 'react';

// styling import
import './Project.scss';

// icon imports
import Github from '../../assets/github.svg'
import Deployed from '../../assets/deployed.svg'

const Project = (props) => {
    return ( 
        <div className="project">
            <img src={props.imageSource} alt="screenshot of project" className="img-source" />
            <h3 className="name">{props.name}</h3>
            <p className="team">{props.team}</p>
            <div className="links-container">
                <a href={props.githubLink} className="icon-links" rel="noopener noreferrer" target="_blank">
                    <img src={Github} alt="github icon" className="icons" />
                    <p>Source Code</p>
                </a>
                <a href={props.deployedLink} className="icon-links" rel="noopener noreferrer" target="_blank">
                    <img src={Deployed} alt="deployed icon" className="icons"/>
                    <p>Deployed App</p>
                </a>
            </div>
            <p className="skills"><b>Techs:</b><br /> {props.skills}</p>
            <p className="description">{props.descBullet1}</p>
            <p className="description">{props.descBullet2}</p>
            <p className="description">{props.descBullet3}</p>
        </div>
     );
}
 
export default Project;