import React from 'react';

// library imports
import { NavLink } from 'react-router-dom';

// styling imports 
import './Home.scss';

// image imports
import Github from '../../assets/github.svg';
import LinkedIn from '../../assets/linkedin.svg';

const Home = () => {

    return (
        <div className="home-wrapper">
            <div className="info-container">
                <h2 className="name">Elijah McKay</h2>
                <h3 className="title">Software Engineer</h3>
                <div className="contact-icons">
                    <a href="https://github.com/ElijahMcKay" rel="noopener noreferrer" target="_blank"><img src={Github} className="icons" alt="programming icon" /></a>
                    <a href="https://linkedin.com/in/elijahmckay" rel="noopener noreferrer" target="_blank"><img src={LinkedIn} className="icons" alt="programming icon" /></a>
                </div>
            </div>
            <NavLink to="/contact" activeClassName="active-nav" className="navlink"><button className="contact">Contact Me</button></NavLink>
        </div>
     );
}
 
export default Home;