import React from "react"

// library imports
import { NavLink } from 'react-router-dom'

// styling imports
import "./NavBar.scss"

const NavBar = () => {

  return (
    <div className="navbar-wrapper">
        <NavLink to="/" className="home-link"><h2 className="logo">&lt;EM /&gt;</h2></NavLink>
        <div className="navlinks">
            <NavLink exact to="/" activeClassName="active-nav" className="navlink">Home</NavLink>
            <NavLink to="/skills" activeClassName="active-nav" className="navlink">Skills</NavLink>
            <NavLink to="/projects" activeClassName="active-nav" className="navlink">Projects</NavLink>
            <NavLink to="/about" activeClassName="active-nav" className="navlink">About</NavLink>
            <NavLink to="/contact" activeClassName="active-nav" className="navlink">Contact</NavLink>
        </div>
    </div>
  )
}

export default NavBar
