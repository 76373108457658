import React from 'react';

// component imports 
import Project from '../Project/Project';

// styling imports
import './Projects.scss';

// image imports
import Project1Source from '../../assets/g2-kit-builder.png';
import Project2Source from '../../assets/one-step-mud.png';
import Project3Source from '../../assets/project-firefly.png';
// import Project1Source from '../../assets/github.svg';
// import Project1Source from '../../assets/github.svg';

const Projects = () => {

    const oneStepMud = {
        bullet1: `- Given 4 days to design and build a multi-user dungeon game based on a provided project`,
        bullet2: `- Used Python/Django to create an algorithm that generates 500 unique rooms for players to traverse and interact with other players`,
        bullet3: `- Created maze in browser using React, JavaScript Canvas API, HTTP requests, tilemaps, and sprite bitmaps`
    }

    const g2KitBuilder = {
        bullet1: `- Tasked with building software to reduce the amount of salesman time spent on the road and in meetings discussing specifications of new packaging`,
        bullet2: `- Built a React web app to streamline the new product data collection process, easily save/retrieve previously quoted items with a MongoDB/Node.js backend, and implemented a secure onboarding system with JSON Web Tokens and private routes.`
    }

    const projectFirefly = {
        bullet1: `- Tasked with getting existing kid’s coding game ready for business and Y Combinator application`,
        bullet2: `- Implemented subscription payment models with Stripe, a Zendesk customer service system, refactored marketing site for better SEO and user interaction`,
        bullet3: `- Worked on a brownfield project with a team with code reviews, and progress objectives`
    }

    return (
        <div className="projects-wrapper">
            <div className="info-container">
                <h2 className="title">Projects</h2>
                <div className="project-container">
                    <Project
                    imageSource={Project2Source}
                    name="OneStep MUD"
                    team="Team of 5, Greenfield"
                    githubLink="https://github.com/Trying-our-best"
                    deployedLink="https://cs-bw1-tob.netlify.com/"
                    skills="React, Canvas API Python/Django, PostgreSQL"
                    descBullet1={oneStepMud.bullet1}
                    descBullet2={oneStepMud.bullet2}
                    descBullet3={oneStepMud.bullet3}
                    />
                    <Project
                    imageSource={Project1Source}
                    name="G2 Kit Builder"
                    team="Solo, Greenfield"
                    githubLink="https://github.com/G2-Supply/g2-kit-builder-fe"
                    deployedLink="https://g2kitbuilder.com/"
                    skills="React, Node, Express, MongoDB/Mongoose"
                    descBullet1={g2KitBuilder.bullet1}
                    descBullet2={g2KitBuilder.bullet2}             />
                    <Project
                    imageSource={Project3Source}
                    name="Project Firefly"
                    team="Team of 7, Brownfield"
                    githubLink="https://github.com/ElijahMcKay/project-firefly-be"
                    deployedLink="https://festive-curie-340f22.netlify.com/"
                    skills="React, Node, Express, MongoDB, Stripe API"
                    descBullet1={projectFirefly.bullet1}
                    descBullet2={projectFirefly.bullet2}                          
                    descBullet3={projectFirefly.bullet3}  
                    />                        
                </div>
                <h3>See more on <a href="https://github.com/ElijahMcKay" target="_blank" rel="noopener noreferrer"><u>GitHub</u></a></h3>
            </div>
        </div>
     );
}

export default Projects;