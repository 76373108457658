import React from "react";

// styling imports
import './Contact.scss';

// image imports
import Email from '../../assets/email.svg';
import LinkedIn from '../../assets/linkedin.svg';
import Github from '../../assets/github.svg';

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (
        <div className="contact-wrapper">
            <h3 className="title">Contact</h3>
            <form
            onSubmit={this.submitForm}
            action="https://formspree.io/mqkbrezk"
            method="POST"
            className="email-form"
            >
                <label className="label">Email</label><br />
                <input className="input" type="email" name="email" />
                <label className="label">Message</label><br />
                <textarea className="input message" type="text" name="message" />
                {status === "SUCCESS" ? <p>Thanks!</p> : (
                <div className="response-container">
                  <div className="btn-wrapper">
                    <button>Submit</button>
                    <div></div>
                  </div>
                  <p className="or">or</p>
                  <div className="link-wrapper">
                    <a href="mailto:elijahmckay10@gmail.com" target="_blank" rel="noopener noreferrer" style={{marginBottom: "1.5rem"}}><img src={Email} alt="email icon" />elijahmckay10@gmail.com</a>
                    <a href="https://linkedin.com/in/ElijahMcKay" target="_blank" rel="noopener noreferrer" style={{marginBottom: "1.5rem"}}><img src={LinkedIn} alt="github icon"/>LinkedIn</a>
                    <a href="https://github.com/ElijahMcKay" target="_blank" rel="noopener noreferrer"><img src={Github} alt="github icon"/>GitHub</a>
                  </div>
                </div>
                )}
                {status === "ERROR" && <p>Ooops! There was an error.</p>}
            </form>
        </div>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}